// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")
require("local-time").start()

import 'bootstrap'
import 'data-confirm-modal'
import "cocoon-js";

window.Rails = Rails

$(document).ready(function () {
	// get current URL path and assign 'active' class
	var pathname = window.location.pathname;
	$('.navbar > div > ul > li > a[href="' + pathname + '"]').parent().addClass('active');
})
